var policyRedesign = {
    ui: {
        personalization: {
            urlRefer: function () {
                var selector = {
                    accordionContainer: ".collapse-expand-a",
                    accordionReferred: ".policy-accordion.collapsible.url-referred",
                    accordionDefaultExpanded: ".policy-accordion.collapsible.default-expanded",
                    accordionTitle: "h2",
                    cssClass: {
                        open: "open",
                        expand: "expand"
                    }
                }

                function init() {
                    jQuery(selector.accordionContainer).each(function (i) { // each container of accordions (in case multiple)
                        var elmContainer = jQuery(this);
                        var elmReferred = elmContainer.find(selector.accordionReferred);

                        if (elmReferred && elmReferred.length > 0) { // has referred accordion to be expanded
                            elmReferred.addClass(selector.cssClass.open);
                            elmReferred.find(selector.accordionTitle).addClass(selector.cssClass.expand);
                        }
                        else { // has no referred accordion, hence expand the default accordion
                            var elmDefaultExpanded = elmContainer.find(selector.accordionDefaultExpanded);

                            if (elmDefaultExpanded && elmDefaultExpanded.length > 0) {
                                elmDefaultExpanded.addClass(selector.cssClass.open);
                                elmDefaultExpanded.find(selector.accordionTitle).addClass(selector.cssClass.expand);
                            }
                        }

                        elmContainer.each(function (j) { // each accordion
                            var elmAccordion = jQuery(this);
                        });
                    });
                }

                return {
                    init: init
                }
            }
        }
    },

    utility: {}
}

// When the Document Object Model is ready
jQuery(document).ready(function () {
    policyRedesign.ui.personalization.urlRefer().init();
});